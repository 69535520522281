// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics} from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyCQR0WaesZY9hwVnJTWnZJZ-Z46sMYtn3k",

  authDomain: "portfolio-moi.firebaseapp.com",

  projectId: "portfolio-moi",

  storageBucket: "portfolio-moi.firebasestorage.app",

  messagingSenderId: "876568335563",

  appId: "1:876568335563:web:d42ee6ef3bd6663e19b74e",

  measurementId: "G-L64JK4WRW4"

};


// Initialize Firebase

export const FirebaseApp = initializeApp(firebaseConfig);
export const FirebaseAnalytics = getAnalytics(FirebaseApp);